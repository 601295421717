:root {
    --start-color: #00b1e1;
}

div.ModalContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 40px; */
    div.Tags{
        width: 80%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        overflow: auto;
        /* gap: 120px; */
        margin: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        max-height: 400px;
        /* border: 1px solid red; */
        column-width: max-content;
        /* column-count: 3; */
        div.Tag{
            margin-left: 80px;
            display: block;
            width: max-content;
            border: 1px solid #FFFF;
            border-radius: 20px;
            color: #FFFF;
            margin-bottom: 13px;
            
            span{
                margin: 10px;
                font-size: 20px;
            }
        }

        div.Tag:hover{
            cursor: pointer;
        }
    }

    div.ConfirmBtn{
        margin-top: 40px;
        display: flex;
        justify-content: center;
        button{
            outline: none;
            border: 1px solid #FFFF;
            color: var(--start-color);
            background-color: #FFFF;
            border-radius: 20px;
            width: 190px;
            height: 35px;
            font-size: 25px;
            font-weight:unset;
        }
        button:hover{
            cursor: pointer;
        }
    }
}

@media (max-width: 800px){
    div.ModalContainer{
        div.ConfirmBtn{
            margin-top: 0px;

        }
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        /* gap: 2vh; */
        height: 100%;

        div.Tags{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            flex-wrap: nowrap;

            /* gap: 1px; */
            height: 80vh;
            width: 100vw;
            overflow: auto;
            
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
    }
    
    div.Tags::-webkit-scrollbar{
        display: none;
    }
}