div.Container{
    margin: 0;
    padding: 0;
    height: 300px;
    width: 350px;
    cursor: pointer;
    /* border: 6px solid black; */

    div.Wrapper{
        /* border: 5px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        
        img.BgStore{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
        
        div.NameAndAddress{
            /* border: 5px solid green; */
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            
            width: 95%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            div.ShopName{
                span{
                    font-family: Heebo,Regular;
                    font-size: 30px;
                    font-weight: 400;

                }
            }
            
            div.ShopAddress{
                margin-top: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                
                div.ShopIconAddress{
                    margin-left: 10px;
                    margin-top: 3px;
                    img{
                        width: 15px;
                    }
                }
                
                div.ShopTextAddress{
                    span{
                        font-family: Heebo,Regular;
                        font-size: 20px;
                        color: #808080;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        
        div.ShopImage{
            /* border: 5px solid brown; */
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;

            img{
                margin-bottom: 15px;
                width: 70%;
            }
        }
    }
}
