div.Container{
    margin: 0 auto;
    padding: 0;
    /*background: url("https://bafalo.my-shop.co.il/bg.jpg") no-repeat #FFFFFF;*/
    background: var(--bg-image);
    background-size: cover;
    background-position: center;
    height: 100vh;
    max-height: 100%;
    overflow:auto;

    div.TopMain{
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px;

        div.Title{
            margin-right: 40px;
            font-size: 30px;
            font-weight: 550;
            color: #fff;
        }
    }
    
    div.ScreenBody{
        padding: 0;
        margin: 0;
        padding-bottom: 50px;

        display: flex;
        justify-content: center;


        -webkit-column-count: 2;
        -moz-column-count: 2;
             column-count: 2;

             div.StoreList{
            height: 100%;
            max-width: 1000px;
            min-width: 360px;

            display: flex;
            flex-wrap: wrap;
            /* gap: 10px; */
            justify-content: center;
            /* padding-bottom: 100%; */
        }

        div.StoreList > * {
            padding: 15px;
        }
    }
}

/* @media (max-width: 800px){
    div.Container{
        margin: 0;
        padding: 0;
        background: url(../../assets/bg.jpg) no-repeat;
        background-size: 1200px;
        background-position: top;
        background-color: #FFFFFF;
        
        div.TopMain{
            div.Title{
                margin-right: 40px;
                font-size: 26px;
                font-weight: 550;
                color: #FFFFFF;
            }
        }
    }
} */
