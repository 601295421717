div.Container{
    display: flex;
    /* gap: 10px; */
    justify-content: flex end;
    width: 100%;
    
    div.Unit{
        width: 60px;
        height: 22px;
        font-size: 15px;
        line-height: 21px;
        border-radius: var(--brdRadius);
        border:1px solid var(--mainColor);
        margin-bottom: 5px;
        font-size: 17px;
    }

    div.Unit:hover{
        cursor: pointer;
    }
}

div.Container > * + *{
    margin-right: 5px;
}