div.Container{
    /* border: 2px solid red; */
    display: flex;
    border-bottom: 2px solid #D3D3D3;
    margin-top: 10px;
    justify-content: center;
    z-index: 1;

    div.RightElements{
        /* border: 5px solid blue; */
        z-index: 1;
        width: max-content;
        
        div.ItemCheckbox{
        }
    }

    div.MidElements{
        /* border: 5px solid red; */
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 5px;
        margin-right: 5px;

        div.ImagFlagDetalisWrapper{
            /* border: 1px solid blue; */
            overflow: hidden;
            display: flex;
            align-items: center;

            div.ImageAndFlag{
                /* border: 1px solid red; */
                padding: 0;
                margin: 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .ItemImage{
                    /* border: 1px solid red; */
                    max-width:  90px;
                }

                div.ItemFlags {
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    div.iFlags {
                        color: var(--mainWhite);
                        width: 100%;
                        max-width: 200px;
                        border-radius: 30px;
                        height: 20px;
                        font-size: 12px;
                        font-weight: 500;
                        
                        &.iNew {
                            border:1px solid  #FF8000;
                            background-color: #FF8000;
                        }
                        
                        &.iSale {
                            border:1px solid  #E10000;
                            background-color: #E10000;
                        }
                        
                        &.iSeason {
                            border:1px solid  #FF8000;
                            background-color: #FF8000;
                        }
                    }
                }
            }

            div.ItemDetails{
                /* border: 1px solid red; */
                height: 100%;
                max-width: 70%;
                padding: 0;
                margin: 0;
                margin-right: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: right;
                overflow: hidden;
                overflow-x: auto;
                line-height: 120%;

                div.ItemName{
                    /* border: 1px solid red; */
                    font-size: 18px;
                    font-weight: 500;
                    max-width: 180px;
                    overflow: hidden;
                    text-overflow: ellipsis;  
                    cursor: default;
                }

                div.ItemPrice{
                    font-size: 18px;
                    font-family: 'Heebo', sans-serif;
                    font-weight: 500;
                    cursor: default;
                }

                div.ItemQuantity{
                    /* border: 1px solid red; */
                    font-size: 18px;
                    font-weight: 500;
                    text-align: right;
                    cursor: default;
                }
            }
        }

        div.Comment{
            /* border: 1px solid blue; */
            display: flex;
            align-items: center;
            margin-top: 10px;
            width: 100%;

            div.Title{
                font-size: 13px;
                text-decoration: underline;
                cursor: pointer;
            }

            div.InputText{
                width: 100%;
                input{
                    width: 100%;
                    font-size: 13px;
                    outline: none;
                    border: none;
                    font-family: 'Heebo', sans-serif;
                }
            }
        }
    }

    div.LeftElements{
        /* border: 5px solid blue; */
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: max-content;
        
        div.MoreInfo{
            /* border: 1px solid red; */
            margin-bottom: auto;
            width: 100%;
            direction : ltr;

            div.MoreInfoTooltip{
                /* border: 1px solid red; */
                width: max-content;
                font-size: 10px;
                text-decoration: underline;
                text-align: left;
                
                .Tooltip{
                    max-width: 400px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                }
            }
            div.MoreInfoTooltip > .label:hover{
                cursor: help;
                opacity: .6;
            }
        }
        
        div.Buttons{
            /* border: 2px solid green; */
            margin-bottom: auto;

            button{
                border-radius: 25px;
                width: 100px;
                height: 28px;
                font-size: 14px;
                /* font-weight: 500; */
                color: #FFF;
                font-family: 'Heebo', sans-serif;
                background-color: #FFFFFF;
                outline: none;
                cursor: pointer;
            }
            div.EditButton{
                margin-bottom: 5px;
            }
            div.RemoveButton{
            }
        }

    }
}

@media (max-width: 500px){
    div.Container{
        div.MidElements{
            div.ImagFlagDetalisWrapper{
                div.ImageAndFlag{

                    .ItemImage{
                        width:  60px;
                    }

                    div.ItemFlags{
                        div.iFlags{
                            height: 18px;
                        }
                    }
                }
                div.ItemDetails{
                    div.ItemName{
                        max-width: 100px;
                        font-size: 14px;
                    }
                    div.ItemPrice{
                        font-size: 12px;
                    }

                    div.ItemQuantity{
                        font-size: 12px;
                    }
                }
            }
        }

        div.LeftElements{
            div.Buttons{
                button{
                    height: 24px;
                    width: 80px;
                }
            }
        }
    }
}