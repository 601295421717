div.ModalContainer{
    width: 100%;

    div.CloseBtn{
        text-align: right;
        color: #808080;
        font-weight: 700;
        font-size:23px;
        
        span:hover{
            cursor: pointer;
        }
    }

    div.Content{
        height: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: #FFF;
        padding: .2rem;

        div.BodyContent{
            text-align: center;
            color: #000;
            font-size: 24px;
            white-space: nowrap;
            
            :nth-child(3){
                font-weight: 600;
            }
            :nth-child(4){
                span{
                    font-weight: 600;
                    text-decoration: underline;
                    cursor: pointer;
                }

                span:hover{
                    opacity: .6;
                }
            }
        }

        div.FootContent{
            text-align: center;
            font-size: 15px;
            white-space: nowrap;

            :nth-child(1){
                text-decoration: underline;
            }
        }

        p{
            margin: 0;
        }
    }
}
