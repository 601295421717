/* @media screen and (min-width: 601px) { */

div.Container{
    /* border: 4px solid brown; */
    height: 100%;
    overflow: auto;

    div.ToppingsDescription{
        font-size: 14px;
        margin-bottom: 5px;
    }

    div.ItemToppingList{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* gap: 10px; */
        padding: 0 15% 0 15%;

        div.ItemTopping{
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 20px;
        }
    }

    div.ItemToppingList > * + *{
        margin-top: 10px;
    }
}

@media (max-resolution: 110dpi){
    div.Container{
        div.ToppingsDescription{
            font-size: 18px;
        }

        div.ItemToppingList{
            div.ItemTopping{
                font-weight: 500;
                font-size: 20px;
            }
        }
    }
}

@media (min-resolution: 130dpi){
    div.Container{
        div.ToppingsDescription{
            font-size: 12px;
        }

        div.ItemToppingList{
            div.ItemTopping{
                font-weight: 500;
                font-size: 12px;

                input{
                    width: 10px;
                }
            }
        }
    }
}

@media (min-resolution: 150dpi){
    div.Container{
        div.ToppingsDescription{
            font-size: 10px;
        }

        div.ItemToppingList{
            div.ItemTopping{
                font-weight: 500;
                font-size: 10px;
            }
        }
    }
}
/* } */

@media (max-width: 850px){
    div.Container{
        div.ToppingsDescription{
            font-size: 18px;
        }
        
        div.ItemToppingList{
            div.ItemTopping{
                div.WrapBtnAndName{
                    input{
                        width: 20px;
                    }
                    .ItemToppingName{
                        font-size: 17px;
                    }
                }
                .ItemToppingPrice{
                    font-size: 17px;
                }
            }
        }
    }
}