div.ModalContainer{
    /* border: 3px solid red; */
    /* padding: 0 150px 0 150px; */
    width: 500px;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Heebo', sans-serif;
    

    div.closeBtn{
        /* border: 1px solid red; */
        margin-right: auto;
        margin-left: 5%;
        cursor: pointer;
        font-size: 30px;
        font-weight: 400;
    }

    h3{
        text-align: center;
        width: 80%;
        font-size: 25px;
        font-weight: 500;
        font-family: 'Heebo', sans-serif;
    }

    div.Inputs{
        /* border: 1px solid green; */
        outline: none;
        width: 100%;
        
        div.errorMsg{
            /* border: 1px solid red; */
            text-align: center;
            height: 30px;
            padding-top: 5px;
            color: #FF0000;
        }

        * > *{
            margin-right: 10px;
        }
    }


    div.Btns{
        /* border: 3px solid blue; */
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        button{
            font-family: 'Heebo', sans-serif;
            cursor: pointer;
            background-color: unset;
            border-radius: 30px;
            height: 45px;
            width: 120px;
            font-size: 22px;
        }
    }

    div.Btns > * + *{
        margin-right: 30px;
    }
}

@media (max-width: 520px){
    div.ModalContainer{
        width: 350px;
        height: 190px;

        div.closeBtn{
            font-size: 25px;
        }

        h3{
            font-size: 25px;
        }

        div.Inputs{
            div.errorMsg{
                font-size: 12px;
            }
        }

        div.Btns{
            margin-top: 0px;
            margin-bottom: 10px;

            button{
                height: 37px;
                width: 110px;
                font-size: 18px;
            }
        }
    }
}