div.ModalContainer{
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div.Header{
        /* border: 1px solid blue; */
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
    }

    div.CartProducts{
        /* border: 1px solid green; */
        margin: auto;
        width: 90%;
        height: 80%;
        overflow: auto;
        
        display: flex;
        flex-wrap: wrap;

        div.ItemContainer{
            /* border: 4px solid red; */
            width: 10rem;
            /* height: 15rem; */
            flex: 0 1 50%;
            cursor: pointer;

            div.ItemImage{
                /* border: 4px solid purple; */
                margin: auto;
                max-width: 50%;
                position: relative;

                div.PriceCircle{
                    position: absolute;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-around;
                    flex-direction: row;
                    top: 0%;
                    right: -5%;
                    border-radius: 50px;
                    color: #FFF;
                    font-family: 'Heebo';
                    font-size: 100%;
                    padding: 7%;
                    padding-bottom: 9%;
                    height: 2.4rem;

                    div.Agorot{
                        margin-bottom: 30%;
                        font-size: .5em;
                        line-height: 1.0rem;
                        height: 0.8rem;
                    }

                    div.Shekels{
                        font-size: 1.3em;
                        line-height: 1.8rem;
                        height: 0.8rem;
                        
                    }
                    div.Curr{
                        font-size: 0.8em;
                        line-height: 2rem;
                        height: 0.8rem;
                    }
     
                }
            }

            div.ItemName{
                /* border: 1px solid red; */
                margin: auto;
                text-align: center;
                width: 60%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 1.1rem;
                font-weight: 500;
            }
        }

        div.ItemContainer:hover{
            opacity: .8;
        }
    }

    div.NoThanksFooter{
        /* border: 1px solid green; */
        display: flex;
        justify-content: center;

        span{
            /* border: 1px solid red; */
            font-size: 1.8rem;
            color: #7d7d7d;
            text-decoration: underline;
            cursor: pointer;
        }
        span:hover{
            opacity: .7;
        }
    }
}

@media (max-width: 600px){
    div.ModalContainer{
        div.Header{
            font-size: 1.5rem;
        }
    
        div.CartProducts{
           
            div.ItemContainer{

    
                div.ItemImage{
                    max-width: 75%;
                    padding-right: 5%;

                    div.PriceCircle{
                        
                        div.Agorot{
                        }
    
                        div.Shekels{
                        }
                        div.Curr{
                        }
         
                    }
                }
    
                div.ItemName{

                }
            }
        }
    
        div.NoThanksFooter{

            span{

            }
 
        }
    }
}