div.Container{
    /* border: 4px solid blue; */
    width: 100%;
    height: 100%;
    padding-right: 1%;
    padding-left: 1%;
    background-color: #000;

    display: flex;
    align-items: center;
    overflow: hidden;
    /* justify-content: space-between; */

    div.MenuBtns{
        /* border: 1px solid red; */
        display: flex;
        /* flex-flow: row-reverse wrap; */
        flex-flow: row-reverse;
        align-items: center;
        justify-content: start;
        width: 45%;
        height: 90%;
        overflow: hidden;
        
        div.menubtn {
            padding: 10px;
            background-color: transparent;
            border: 0px;
            color: var(--mainWhite);
            font-size: 1.3vw;
            font-weight: 200;
            cursor: pointer;
        
            &.menubtnActive {
                font-weight: 400;
                text-decoration: underline var(--mainWhite);
            }
        }
    }

    div.BigappsLogo{
        /* border: 1px solid red; */
        height: 90%;
        width: max-content;
        margin-left: auto;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        *:hover{
            opacity: .9;
        }
    }

    div.MobileBtns{
        /* border: 2px solid red; */
        height: 77%;
        width: max-content;

        display: flex;
        justify-content: end;
        align-items: center;

        *{
            margin-left: 7%;
            cursor: pointer;
        }

        *:hover{
            opacity: .8;
        }
    }

}