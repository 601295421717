div.Container{
    /* border: 1px solid red; */
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;


    div.cancleBtn{
        position: absolute;
        font-size: 25px;
        cursor: pointer;
        left: 10px;
        top: 10px;
    }

    
    div.Content{
        /* border: 1px solid red; */
        width: 80%;
        text-wrap: wrap;
        margin: auto;
        align-text: right;
        word-break: break-word;
    }
}