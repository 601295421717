.slider-container{
    /* border: 5px solid red; */
    overflow: hidden;
    padding: 0;
    direction: ltr;

    margin: 20px auto;
    margin-bottom: 35px;
    width: 100%;
    height: 180px;

    /* border-radius: 10px; */
    position: relative;

    box-shadow: .2vw .25vw 6px rgba(0,0,0,.2);
}

.slider-container > .slider{
    /* border: 3px solid blue; */
    
    margin: 0;
    padding: 0;


    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    height: 100% !important;
    /* transition: transform .6s */
}


.slider > .slide{
    /* border: 3px solid green; */

    direction: ltr;
    /* object-fit: cover; */
    width: 100vw;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;

    cursor: pointer;

}

.slide > img{
    height: 100%;
    width: 100%;
    /* width: 20% !important; */
    object-fit: cover;
    
}

/* .slide > .price{
    direction: ltr;
    font-family: Georgia;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 30%;
} */

/* .shekels{
    margin-bottom: 3vw;
    font-size: 10vw;
}

.agorot{
    font-size: 3vw;
    margin-bottom: 20%;
} */

@media (max-width: 750px){
    div.slider-container{
        height: 115px;
    }
}
@media (max-width: 500px){
    div.slider-container{
        height: 85px;
    }
}
@media (max-width: 350px){
    div.slider-container{
        height: 70px;
    }
}

/* .controls{
    cursor: pointer;
}

.prev{
    position: absolute;
    top: 5px;
    left: 10px;
}
.next{
    position: absolute;
    top: 5px;
    right: 10px;
} */

