div.Container{
    padding: 5%;
    text-align: center;
    width: 300px;
    height: 315px;
    border-radius: 5px;
    background-color: #FFFF;
    box-shadow: 3px 3px 12px rgb(202, 202, 202, 0.45), inset -1px -1px 4px rgb(202, 202, 202,0.66) ;
    position: relative;

    .redCircle{
        border: .1vw solid #ff0000;
        border-radius: 100%;
        position: relative;
        position: absolute;
        left: 1vh;
        top: 1vh;
        width: 1.2em; height: 1.2em;
    }
    .redCircle::before{
        content: "";
        display: block;
        position: absolute;
        width: .85em; height: .85em;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ff0000;
        border-radius: 100%;
    }

    .greenCircle{
        border: .1vw solid #1bb449;
        border-radius: 100%;
        position: relative;
        position: absolute;
        left: 1vh;
        top: 1vh;
        width: 1.2em; height: 1.2em;
    }
    .greenCircle::before{
        content: "";
        display: block;
        position: absolute;
        width: .85em; height: .85em;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        background-color: #1bb449;
        border-radius: 100%;
    }

    div.ShopImage{
        margin-top: 10px;
        margin-bottom: 10px;

        img{
            max-width: 65%;
        }
    }

    div.NameAndAddress{
        div.ShopName{
            span{
                font-size: 20px;
                font-weight: bold;
                text-overflow: ellipsis;
            }
        }
        
        div.shopType{
            font-size: 12px;
        }

        div.ShopAddress{
            display: flex;
            justify-content: center;
            align-items: center;


            div.ShopIconAddress{
                margin-left: 5px;
                img{
                    width: 10px;
                }
            }
            
            div.ShopTextAddress{
                span{
                    font-size: 20px;
                    text-overflow: ellipsis;
                }
            }
    

        }
    }
    div.DeliveryTime{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size:20px;

        div.bikeImg{
            margin-left: 7px;
            width: 25px;
            /* height: 20px; */
        }
    }

}

div.Container:hover{
    cursor: pointer;
    box-shadow: 3px 3px 12px rgb(202, 202, 202, 0.45), inset -10px -10px 15px rgb(202, 202, 202,0.16) ;
}


@media (max-width: 550px){
    div.Container{
        /* background-color: red; */
        width: 150px;
        height: 190px;

    }
}